.project {
    width: 100%;
    height: 100%; /*calc(100vh - 100px)*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color:aliceblue;
  }
  
  .project h1 {
    margin-top: 50px;
    color: #3e497a;
    font-size: 30px;
    text-align: center;
  }
  .project img {
    width: 700px;
    border-radius: 10px;
    padding-bottom: 25px;
  }
  
  .project p {
    font-size: 25px;
    color: #3e497a;
    text-align: center;
    width: 70%;
  }

  .note {
    font-size: 25px;
    font-style: italic;
    color: #3e497a;
    text-align: center;
    width: 70%;
  }
  
  .project svg {
    font-size: 60px;
    color: #3e497a;
  }