.home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
  }
  
  .about {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #363636 ; 
    /*background-image: linear-gradient(to right, rgb(255, 49, 49) , rgb(251, 251, 50));*/
    /*background-image: linear-gradient(to right, #2A363B , #9DE0AD);*/
    color: white; /*#f0f0f0*/

  }
  
  .about h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 40px;
    color: white;
    height: 20px;
    font-weight: 500;
  }
  
  .about .prompt {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    width: 50%;
    font-size: 25px;
    font-weight: 350;
  }
  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
  }

  .about .currently {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    width: 50%;
    font-size: 18px;
    font-weight: 350;
  }
  
  .about .previously {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    width: 50%;
    font-size: 18px;
    font-weight: 350;
  }
  .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: aliceblue;
  }
  
  .skills h1 {
    color: #000000;
    margin-bottom: -10px;
  }
  
  .skills .list {
    list-style: none;
    width: 60%;
    color: #000000;
  }
  
  .list span {
    font-size: 20px;
  }
  
  @media only screen and (max-width: 600px) {
    .about h2 {
      font-size: 40px;
    }
  
    .about .prompt {
      margin-top: 10px;
      font-size: 20px;
      width: 80%;
    }
  
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h2 {
      font-size: 30px;
    }
  }