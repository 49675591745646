.footer {
    width: 100%;
    height: 200px;
    background: #363636;
    /*background-image: linear-gradient(to right, #2A363B , #9DE0AD);*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .socialMedia svg {
    /*color: black;*/
    color: white;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
  }
  
  .footer p {
    text-align: center;
    color: white;
    font-size: 20px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  @media only screen and (max-width: 600px) {
    .footer svg {
      font-size: 50px;
    }
  }